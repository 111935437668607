<template>
  <v-container class="pa-0 ma-0" fluid>
    <v-progress-linear :active="this.dataLoading" color="primary" indeterminate></v-progress-linear>
    <v-expansion-panels class="pa-0 ma-0">
      <v-expansion-panel
          class="pa-0 ma-0 elevation-0"
      >
        <v-expansion-panel-header :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header-tab card-header">
          <v-card-title>

            <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
                            <span>{{ $t('erp.lang_Inventorycontrol') }} <span
                                class="ml-5 badge rounded-xl badge-success">{{ this.qty }}</span></span>

            </div>

            <div class="btn-actions-pane-right actions-icon-btn">
              <b-input-group width="100%">
                <b-form-input :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              :placeholder="$t('datatables.lang_search')"
                              @focus="showTouchKeyboard"
                              v-model="search"/>
              </b-input-group>
            </div>
          </v-card-title>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pa-0 ma-0 elevation-0 v-expansion-panel-content__wrap">
          <v-container class="pa-0 ma-0" fluid>
            <v-expansion-panels accordion active-class="grey lighten-3" class="elevation-0 pa-0 ma-0"
                                focusable
                                style="padding: 0px !important;"
            >
              <v-expansion-panel
                  :key="i"
                  class="elevation-0 pa-0 ma-0"
                  :active-class="[$vuetify.theme.dark? 'grey darken-2' : '']"
                  focusable
                  fu
                  style="padding: 0px !important;"
                  v-for="(StorageItem,i) in this.listStorages"
                  v-model="StorageItem.active"
              >
                <v-expansion-panel-header class="ma-0">
                  {{ StorageItem.name }}
                  <template v-slot:actions>
                    <v-icon color="primary">settings</v-icon>
                  </template>
                  <v-spacer/>

                  <div class="text-right">
                    <v-chip
                        :color="getColor(StorageItem.currentQTY , StorageItem.controlDATA.minBestand , StorageItem.controlDATA.sollBestand)"
                        class="mr-2"
                        small>
                      {{ StorageItem.currentQTY }}
                    </v-chip>
                  </div>

                </v-expansion-panel-header>
                <v-expansion-panel-content
                    class="pa-0 ma-0 v-expansion-panel-content v-expansion-panel-content__wrap pa-0"
                    :style="$vuetify.theme.dark? 'padding: 0px !important; background-color: #121212;' : 'padding: 0px !important;'">

                  <v-container class="pa-0 ma-0" fluid>
                    <v-row align="center" class="pa-0 ma-0" justify="center">
                      <v-col cols="12" md="5" sm="6">
                        <b-form-group
                            :label="$t('erp.lang_warecreate_sollQTY')"
                            class="mb-0"
                        >
                          <b-form-input :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                        :class="[$vuetify.theme.dark? 'dark-bg' : '']"
                                        dense
                                        outlined type="number"
                                        v-model="StorageItem.controlDATA.sollBestand"
                          />
                        </b-form-group>
                      </v-col>

                      <v-col cols="12" md="5" sm="6">
                        <b-form-group
                            :label="$t('erp.lang_warecreate_minqty')"
                            class="mb-0"
                        >
                          <b-form-input :class="[$vuetify.theme.dark? 'dark-bg' : '']" :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                        type="number"
                                        v-model="StorageItem.controlDATA.minBestand"/>
                        </b-form-group>
                      </v-col>

                      <v-col class="" cols="12" md="2" sm="12">
                        <button
                            :disabled="(StorageItem.controlDATA.sollBestand === '' || StorageItem.controlDATA.minBestand === '') || (StorageItem.controlDATA.sollBestand === '' && StorageItem.controlDATA.minBestand === '')"
                            @click="save(StorageItem)"
                            block class="mt-7 btn btn-primary btn-block" color="primary"
                            style="bottom: 0"
                            type="button">
                          {{ $t('generic.lang_save') }}
                        </button>
                      </v-col>

                      <v-col class="text-right pa-0 ma-0 pr-3" cols="12"
                             v-if="$vuetify.breakpoint.xsOnly">
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn @click="addSupplier()" class="mx-auto"
                                   color="primary"
                                   fab
                                   small v-on="on">
                              <v-icon>add</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('erp.lang_ware_supplier_create') }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-expansion-panel-content>


              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>

        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel
          class="pa-0 ma-0"
      >
        <v-expansion-panel-header :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header-tab card-header">
          <v-card-title class="">

            <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
              <span>{{ $t('erp.lang_nav_supplier') }} <span
                  class="ml-5 badge rounded-xl badge-primary">{{ this.suppliersDATA.length }}</span></span>

            </div>

            <div class="btn-actions-pane-right actions-icon-btn">
            </div>
          </v-card-title>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-row>
            <v-col class="" cols="12">
              <v-form @submit="false" lazy-validation ref="suppliersForm">
                <v-data-table :headers="headers" :items="suppliersDATA"
                              hide-default-footer
                              :no-data-text="$t('generic.lang_noEntriesFound')">

                  <template v-slot:header.Action="{}">
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn :disabled="suppliersDATA.length === suppliersList.length"
                               @click="addSupplier()"
                               class="mx-auto"
                               color="primary"
                               fab icon
                               small
                               v-on="on">
                          <v-icon>add</v-icon>
                        </v-btn>
                      </template>
                      <span><span>{{ $t('erp.lang_ware_supplier_create') }}</span></span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.supplier="{item}">

                    <v-autocomplete

                        :items="suppliersList.map((elt) => {
                                                if(suppliersDATA.map((x) => x.supplier).includes(elt.id))
                                                    elt.disabled = true;
                                                else
                                                    elt.disabled = false;
                                                return elt;
                                            })"
                        :rules="[ v => !!v]"
                        class="pt-7" dense hide-selected
                        item-text="supplier_name" item-value="id" outlined
                        v-model="item.supplier"
                        value="id">
                    </v-autocomplete>
                  </template>

                  <template v-slot:item.number="{item}">
                    <v-text-field  class="pt-7" dense outlined v-model="item.number"/>
                  </template>

                  <template v-slot:item.optSupplierItemName="{item}">
                    <v-text-field  class="pt-7" dense outlined v-model="item.optSupplierItemName"/>
                  </template>

                  <template v-slot:item.package="{item}">
                    <v-select :items="packages" :rules="[ v => !!v]" class="pt-7" dense item-text="name"
                              item-value="id"
                              outlined style="min-width: 130px;width: 100%" v-model="item.package"/>
                  </template>

                  <template v-slot:item.unitPerPackage="{item}">

                    <v-text-field :rules="[ v => !!v]" class="pt-7" dense outlined type="number"
                                  v-model="item.unitPerPackage"
                    />
                  </template>

                  <template v-slot:item.pricePerUnit="{item}">
                    <v-text-field :rules="[ v => !!v]" class="pt-7" dense outlined v-model="item.pricePerUnit"/>
                  </template>

                  <template v-slot:item.Action="{item}">
                    <v-col cols="12">
                      <button @click="deleteSupplier(item)" class="btn btn-danger btn-block"
                              type="button"
                      >
                        <v-icon color="white">delete</v-icon>
                      </button>
                    </v-col>
                  </template>

                  <template v-slot:footer>
                    <v-col class="pl-4 pr-4 mt-4 pt-0 text-center" cols="12"
                    >
                      <button :disabled="suppliersDATA.length == 0 || suppliersLoad"
                              @click="saveSuppliers()"
                              class="btn btn-primary btn-lg btn-block"
                              type="button">
                        {{ $t('generic.lang_save') }}
                      </button>
                    </v-col>
                  </template>

                </v-data-table>
              </v-form>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>


    <div id="onScreenKeyboardDiv">
      <vue-touch-keyboard
          :accept="hideTouchKeyboard"
          :cancel="hideTouchKeyboard"
          :defaultKeySet="touchKeyboard.keySet"
          :input="touchKeyboard.input"
          :layout="touchKeyboard.layout"
          :options="touchKeyboard.options"
          class="internalWidthExpanded"
          id="onScreenKeyboard"
          v-if="touchKeyboard.visible"
      />
    </div>
  </v-container>
</template>

<script>
import {ENDPOINTS} from "../../../../config";
import {Events} from "../../../../plugins/events";
import mixin from "../../../../mixins/KeyboardMixIns";
//components
import country from "../../../common/country";
import Template from "../../../settings/customerdisplay/Template";

export default {
  name: "ControlSystemComponent",
  components: {
    Template,
    country
  },
  props: {
    itemId: {
      type: Number,
      default: 0
    },
    variations: Array
  },
  mixins: [mixin],
  data: () => {
    return {

      storages: null,
      storage: {},
      qty: 0,
      search: "",
      listStorages: null,
      suppliersDATA: [],
      suppliersList: [],
      packages: [],
      suppliersLoad: false,
      dataLoading: false,
    }
  },
  mounted() {
  //  //console.log(this.variations)
    this.loadData();
  },
  watch: {
    search(val) {
      this.listStorages = this.storages.filter((elt) => elt.name.toLowerCase().includes(val.toLowerCase()));
    }
  },
  computed: {
    itemID: {
      get() {
        return this.itemId == 0 ? this.$route.params.id : this.itemId;
      }
    },
    headers: function () {
      return [
        {
          text: this.$t('erp.lang_nav_supplier'),
          value: "supplier",
          sortable: false
        },
        {
          text: this.$t('erp.lang_orderNo'),
          value: "number",
          sortable: false
        },
        {
          text: this.$t('erp.lang_posItemName'),
          value: "optSupplierItemName",
          sortable: false
        },
        {
          text: this.$t('erp.lang_warecreate_order_abpackeinheit'),
          value: "package",
          sortable: false
        },
        {
          text: this.$t('erp.lang_warecreate_order_abpackentahelt'),
          value: "unitPerPackage",
          sortable: false
        },
        {
          text: this.$t('erp.lang_netPurchasePricePerUnit'),
          value: "pricePerUnit",
          sortable: false
        },
        {
          text: "",
          value: "Action",
          align: "center",
          sortable: false
        },
      ];
    }
  },
  methods: {
    saveSuppliers() {

      if (!this.$refs.suppliersForm.validate()) return;
      let data = {};
      let ids = [];
      if (this.variations !== undefined) {
        ids = this.variations.map((x) => x);
        ids.push(parseInt(this.$route.params.id))
      } else
        ids = this.itemID;
      data.suppliers = JSON.stringify(this.suppliersDATA);
      data.wareID = ids;
      this.suppliersLoad = true;

      this.axios.post(ENDPOINTS.ERP.ITEM.UPDATEWARECONTROLSYSTESUPPLIERS, data).then((res) => {

        if (res.data.STATUS === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_success'),
            color: "success"
          });
        } else if (res.data.STATUS === "FAILED") {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.suppliersLoad = false;
      })
    },
    deleteSupplier(item) {
      let index = this.suppliersDATA.indexOf(item);
      this.suppliersDATA.splice(index, 1);
    },
    addSupplier() {

      //let length = tmp[elt].filter((el) => (el.supplier === "") || (el.number === "") || (el.package === "") || (el.unitPerPackage === "") || (el.pricePerUnit === "")).length;
      this.suppliersDATA.push(
          {
            supplier: "",
            number: "",
            package: "",
            unitPerPackage: "",
            pricePerUnit: "",
          })

    },
    getColor(currentQTY, minQTY, standQTY) {
      if (currentQTY <= minQTY)
        return "error";
      else if (currentQTY >= minQTY && currentQTY < standQTY)
        return "warning";
      else return "success";

    },
    save(item) {
      let data = item.controlDATA;
      let ids = [];
      if (this.variations !== undefined) {
        ids = this.variations.map((x) => x);
        ids.push(parseInt(this.$route.params.id))
      } else
        ids = this.itemID;
      data.storageID = item.id;
      data.wareID = ids;

      this.axios.post(ENDPOINTS.ERP.ITEM.UPDATEWARECONTROLSYSTEMQTY, data).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_success'),
            color: "success"
          });
        } else if (res.data.STATUS === "FAILED") {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {

      })
    },
    loadData() {
      this.dataLoading = true;
      let promises = [];
      promises.push(this.axios.post(ENDPOINTS.ERP.ITEM.GETCONTROLSYSTEM, {
        itemID: this.itemID
      }));

      promises.push(this.axios.post(ENDPOINTS.ERP.SUPPLIERS.GETALL))
      promises.push(this.axios.post(ENDPOINTS.ERP.PACKAGES.GET));
      promises.push(this.axios.post(ENDPOINTS.ERP.ITEM.SUPPLIERSDATA, {
        wareID: this.itemID
      }));

      Promise.all(promises).then(this.axios.spread((...res) => {
        if (res[0].data.STATUS === "SUCCESS") {
          this.storages = res[0].data.storages.filter((elt) => !Array.isArray(elt.controlDATA));

          this.storages.forEach((elt) => {
            if (elt.controlDATA.StoreValue !== undefined) {
              this.qty += elt.controlDATA.StoreValue;
              elt.currentQTY = JSON.parse(elt.controlDATA.StoreValue);

            }
          });

          this.listStorages = this.storages;
        } else if (res[0].data.STATUS === "FAILED") {
          Events.$emit("showSnackbar", {
            message: res[0].data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }

        //suppliersList
        if (res[1].data.STATUS === "SUCCESS") {
          this.storages.forEach((elt) => {
            this.suppliersList = res[1].data.suppliers;
            this.suppliersList.forEach((supplier) => {
              supplier.hidden = false
            });
          });
        } else if (res[1].data.STATUS === "FAILED") {
          Events.$emit("showSnackbar", {
            message: res[1].data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }


        //packages
        if (res[2].data.STATUS === "SUCCESS") {
          this.packages = res[2].data.packages;
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }


        //suppliersDATA
        if (res[3].data.STATUS === "SUCCESS") {
          this.suppliersDATA = res[3].data.suppliersDATA;
        } else if (res[3].data.STATUS === "FAILED") {
          Events.$emit("showSnackbar", {
            message: res[1].data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      })).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.dataLoading = false;
      })
    }
  }
}
</script>

<style scoped>
.v-expansion-panel-content__wrap, .v-expansion-panel-content {
  padding: 0 !important;
}

.v-expansion-panel::before {
  box-shadow: none !important;
}
</style>
