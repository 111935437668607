<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col class="text-right" cols="12">
        <v-spacer/>

        <v-dialog max-width="600px" v-model="dialog">
          <template v-slot:activator="{ on }">
            <button @click="(edit = false) & (dialog = true)"
                    class="btn-shadow d-inline-flex align-items-center btn btn-success pl-1 mx-auto"
                    type="button"
                    v-on="on">
              <v-icon class="mr-1 white--text pa-0" icon="plus">add</v-icon>
              {{ $t('generic.lang_create') }}
            </button>
          </template>

          <v-card>
            <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header mb-2">
              {{ this.formTitle }}
            </v-card-title>

            <v-card-text>
              <v-form lazy-validation ref="form">

                <v-row align-content="center">
                  <v-col cols="12" sm="6">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :rules="[v => !!v]"
                                  @focus="showTouchKeyboard" dense label="EAN" outlined
                                  v-model="editedItem.itemVariationEAN"/>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  :label="this.$t('erp.internal_wareNo')"
                                  @focus="showTouchKeyboard" dense
                                  outlined
                                  v-model="editedItem.itemVariationInternalItemID"/>
                  </v-col>


                  <v-col cols="12" sm="6">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  :label="this.$t('erp.lang_ek_net_short')"
                                  @focus="showTouchKeyboard" dense
                                  outlined
                                  v-model="editedItem.itemVariationNetBuyPrice"/>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  :label="this.$t('erp.lang_vk_brutto')"
                                  :rules="[v => !!v]" @focus="showTouchKeyboard" dense
                                  outlined
                                  v-model="editedItem.itemVariationTotalSalePrice"/>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  :label="this.$t('erp.lang_SizeVariation')"
                                  @focus="showTouchKeyboard" dense
                                  outlined
                                  v-model="editedItem.itemVariationSize"/>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  :label="this.$t('erp.lang_warecreate_color')"
                                  @focus="showTouchKeyboard" dense
                                  outlined
                                  v-model="editedItem.itemVariationColor"/>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  :label="this.$t('erp.lang_quantity')"
                                  @focus="showTouchKeyboard" dense
                                  outlined
                                  v-model="editedItem.itemVariationQTY"/>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-select
                        :items="this.itemDepositSelect"
                        :label="$t('erp.lang_warecreate_pfand')"
                        item-text="name"
                        item-value="id" dense
                        outlined
                        v-if="this.$store.getters['permissions/checkModule'](7)"
                        v-model="editedItem.depositId" :loading="this.loading"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-color-picker :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                    :value="this.editedItem.variationHexColor"
                                    @focus="showTouchKeyboard"
                                    class="ma-2" mode="hexa" show-swatches
                                    v-model="color"></v-color-picker>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>

            <v-card-actions :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="text-right card-footer">
              <v-spacer/>
              <v-btn @click="dialog = false" color="error">{{ $t('generic.lang_cancel') }}</v-btn>
              <v-btn :disabled="this.loading" :loading="this.loading" @click="save" color="success">
                {{ this.edit ? $t('generic.lang_edit') : $t('generic.lang_add') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>

      <v-col class="pa-0" cols="12">

        <Datatable
            :api-endpoint="ENDPOINTS.DATATABLES.ERP.WAREVARIATION"
            :data="this.params"
            :datatable-headers="datatableHeaders"
            :elevation="0"
            :excel-columns="excelColumns"
            :v-show="true"
            @deleteEntries="deleteEntries"
            @deleteEntry="deleteEntry"
            @editEntry="editEntry"
            excel-file-name="warevariation"
            ref="warevariation"
            show-delete-buttons
            show-edit-buttons
        />
      </v-col>

      <v-col cols="12">
        <div id="onScreenKeyboardDiv">
          <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                              :defaultKeySet="touchKeyboard.keySet"
                              :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                              :options="touchKeyboard.options" class="internalWidthExpanded"
                              id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {ENDPOINTS} from "../../../../config";
import {Events} from "../../../../plugins/events";
import mixin from "../../../../mixins/KeyboardMixIns";
import Datatable from "../../../datatable/Datatable";

export default {
  name: "WareVariationComponent",
  components: {Datatable},
  props: {
    oldPrice: Number,
    itemDepositSelect:Array,
  },
  mixins: [mixin],
  data: () => {
    return {
      ENDPOINTS,
      dialog: false,
      loading: false,
      color: null,
      editedItem: {
        itemVariationEAN: "",
        itemVariationInternalItemID: "",
        itemVariationColor: "",
        variationHexColor: "#000000",
        itemVariationSize: "",
        itemVariationNetBuyPrice: "",
        itemVariationTotalSalePrice: "",
        itemVariationQTY: "",
        mainItemID: "",
        depositId: 0,
      },
      defaultItem: {
        itemVariationEAN: "",
        itemVariationInternalItemID: "",
        itemVariationColor: "",
        variationHexColor: "",
        itemVariationSize: "",
        itemVariationNetBuyPrice: "",
        itemVariationTotalSalePrice: "",
        itemVariationQTY: "",
        mainItemID: "",
        depositId: 0,
      },
      loadingDepositGroups:false,
      edit: false,
    }
  },
  computed: {
    params: function () {
      return {
        itemID: this.$route.params.id
      }
    },
    formTitle() {
      return this.edit ? this.$t('erp.editSizeVariation') : this.$t('erp.addSizeVariation')
    },
    datatableHeaders: function () {
      return [
        {
          text: "ID",
          value: "id",
          hide: true
        },
        {text: this.$t("erp.lang_ware_create_id"), value: "itemVariationEAN"},
        {
          text: this.$t("erp.internal_wareNo"),
          value: "itemVariationInternalItemID",
          sortable: false
        },
        {text: this.$t("erp.lang_itemname"), value: "name", sortable: false},
        {text: this.$t("erp.lang_ware_create_bprice"), value: "itemVariationNetBuyPrice"},
        {
          text: this.$t("erp.lang_vk_brutto"),
          value: "itemVariationTotalSalePrice",
        },
        {
          text: this.$t("erp.lang_warecreate_color"),
          value: "itemVariationColor",
          sortable: false
        },
        {
          text: this.$t("generic.lang_size"),
          value: "itemVariationSize",
          sortable: false
        },
        {
          text: "variationHexColor",
          value: "variationHexColor",
          hide: true
        },
        {
          text: this.$t("erp.lang_quantity"),
          value: "itemVariationQTY",
        },

      ];
    },
    excelColumns: function () {
      return [
        {
          label: "ID",
          field: "id",
        },
        {text: this.$t("erp.lang_ware_create_id"), value: "itemVariationEAN"},
        {
          label: this.$t("generic.internal_wareNo"),
          field: "itemVariationInternalItemID",
          sortable: false
        },
        {label: this.$t("erp.lang_itemname"), field: "name"},
        {label: this.$t("erp.lang_ware_create_bprice"), field: "itemVariationNetBuyPrice"},
        {
          label: this.$t("erp.lang_vk_netto"),
          field: "itemVariationTotalSalePrice",
        },
        {
          label: this.$t("erp.lang_warecreate_color"),
          field: "itemVariationColor",
        },
        {
          label: this.$t("generic.lang_size"),
          field: "itemVariationSize",
        },
        {
          label: this.$t("erp.lang_quantity"),
          field: "itemVariationQTY",
        }
      ];
    }
  },
  methods: {
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.edit = false
      })
    },
    editEntry(entry) {
      this.loading=true;
      this.editedItem = Object.assign({}, entry);
      this.editedItem.editItemID = this.editedItem.id;
      this.edit = true;
      this.dialog = true;


      this.axios.post(ENDPOINTS.ERP.EDITITEM.WAREVARIATION.GET, {
        itemVariationID: this.editedItem.editItemID
      }).then((res) => {
        if (res.status === 200) {


          this.editedItem.itemVariationEAN = res.data.formfillData.textFields.identcode;
          this.editedItem.itemVariationInternalItemID = res.data.formfillData.textFields.internID;
          this.editedItem.itemVariationColor = res.data.formfillData.textFields.wareColor;
          this.editedItem.variationHexColor = res.data.formfillData.textFields.wareColorHex;
          this.editedItem.itemVariationSize = res.data.formfillData.textFields.wareSize;
          this.editedItem.itemVariationNetBuyPrice = res.data.formfillData.textFields.buy_price;
          this.editedItem.itemVariationTotalSalePrice = res.data.formfillData.textFields.sell_price;
          this.editedItem.itemVariationQTY = res.data.formfillData.textFields.qty;
          this.editedItem.mainItemID = res.data.formfillData.textFields.mainItemVariation;
          this.editedItem.depositId = res.data.formfillData.textFields.depositId
          if (this.color != null)
            this.color.hexa = this.editedItem.variationHexColor;
          else {
            this.color = {
              hexa: this.editedItem.variationHexColor,
            }
          }

        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(()=>{
        this.loading=false;
      });
    },
    delete(idsToDelete) {
      this.$swal({
        title: this.$t("erp.lang_ware_delete_confirm"),
        text: this.$t("erp.lang_ware_delete_confirm"),
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.axios.post(ENDPOINTS.ERP.EDITITEM.WAREVARIATION.DELETE, {
            deleteItemID: idsToDelete
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t("erp.lang_sizeVariationDeleted"),
                color: "success"
              });


              this.$refs.warevariation.getDataFromApi();
              this.$refs.warevariation.resetSelectedRows();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        }
      });
    },
    deleteEntry(entry) {
      this.delete([entry.id])
    },
    deleteEntries(ids) {
      this.delete(ids)
    },
    save() {
      const url = this.edit ? ENDPOINTS.ERP.EDITITEM.WAREVARIATION.EDIT : ENDPOINTS.ERP.EDITITEM.WAREVARIATION.CREATE;
      this.editedItem.mainItemID = this.$route.params.id;
      if (!this.$refs.form.validate()) return;
      this.loading = true;


      this.axios.post(url, this.editedItem).then((res) => {

        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('erp.lang_sizeVariation_added'),
            color: "success"
          });

          this.$refs.warevariation.getDataFromApi();
          this.$refs.warevariation.resetSelectedRows();
          this.$refs.form.reset();
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        if (this.edit) this.dialog = false;
        this.loading = false;
      });
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.editedItem.itemVariationTotalSalePrice = this.oldPrice
        return;
      }
      this.close()
    },
    color(val) {
      this.editedItem.variationHexColor = this.color.hexa;
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.v-dialog__content, .v-dialog__content--active {

  z-index: 9999;

}
</style>
